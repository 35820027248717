import "reflect-metadata";

import React from "react";
import ReactDOM from "react-dom";

import { Provider, teamsTheme } from "@fluentui/react-northstar";
// import { Debug } from "@fluentui/react-northstar";
// import { TelemetryPopover } from "@fluentui/react-telemetry";

import { Icon } from "@fluentui/react/lib/Icon";
import { initializeIcons } from "@uifabric/icons";

import { QueryClient, QueryClientProvider } from "react-query";

import * as microsoftTeams from "@microsoft/teams-js";
import { Providers, TeamsProvider } from "@microsoft/mgt";

import { makeServer } from "./server";

import reportWebVitals from "./reportWebVitals";

import appStore from "./stores/appStore";
import AppStore from "./models/AppStore";
import { hydrateAppStoreInstanceFromClass } from "./helpers/appStoreHelpers";

import DatabaseProvider from "@nozbe/watermelondb/DatabaseProvider";
import db from "./db/db";

// import App from "./App";
import Preloader from "./Preloader";

import "react-gif-player/dist/gifplayer.css";

import "./index.css";

// Use Mirage for mocked data
if (process.env.REACT_APP_USE_MOCKED_DATA) {
  makeServer({ environment: "development" });
}

// https://docs.microsoft.com/en-us/graph/toolkit/get-started/build-a-microsoft-teams-tab
TeamsProvider.microsoftTeamsLib = microsoftTeams;
Providers.globalProvider = new TeamsProvider({
  clientId: "2441e82a-5e30-4c02-a442-1431f00f29c0",
  authPopupUrl: "/auth.html",
});

const queryClient = new QueryClient();

// Call the initialize API first
console.log("Initializing Teams...");
microsoftTeams.initialize();

// Check the initial theme user chose and respect it
console.log("Getting Teams context...");
microsoftTeams.getContext((context) => {
  microsoftTeams.appInitialization.notifySuccess();
  // debugger;

  // Sample context: {
  // appIconPosition: 420
  // appSessionId: "8ce24063-d5eb-4bb2-a372-9ffa5f5a1de5"
  // chatId: ""
  // entityId: "43287b18-871c-4f19-82a2-986388536409"
  // frameContext: "content"
  // hostClientType: "web"
  // isFullScreen: false
  // isMultiWindow: false
  // jsonTabUrl: "microsoft-teams-json-tab.azurewebsites.net"
  // locale: "en-us"
  // loginHint: "ed@gobionic.io"
  // meetingId: ""
  // ringId: "general"
  // sessionId: "f3fb5488-075e-ee65-44b2-d67575129cfe"
  // subEntityId: "#meeting"
  // teamSiteDomain: "plumage.sharepoint.com"
  // teamSitePath: ""
  // teamSiteUrl: ""
  // tenantSKU: "unknown"
  // theme: "default"
  // tid: "a6efc42b-4c6e-43f4-bb6b-f5a92edf750f"
  // upn: "ed@gobionic.io"
  // userLicenseType: "Unknown"
  // userObjectId: "32f5c3de-f8d7-4687-bc93-08fdb8209044"
  // userPrincipalName: "ed@gobionic.io"
  // }

  console.log("Teams context: ", context);

  // Handle clearing localForage data if user context changes
  // Check if loggedInUser IDs are different
  if (
    appStore.loggedInUser?.id &&
    appStore.loggedInUser?.id !== context.userObjectId
  ) {
    console.warn("New user logged in, resetting appStore");
    const newAppStoreClass = new AppStore();
    hydrateAppStoreInstanceFromClass(appStore, newAppStoreClass);
  }

  appStore.context = context;

  appStore.loggedInUser = {
    id: context.userObjectId,
    userPrincipalName: context.userPrincipalName,
    displayName: undefined,
    mail: undefined,
  };

  if (context?.theme) {
    setTheme(context.theme);
  }

  // Check to see if a "subEntityId" was passed through,
  // e.g. if the tab was opened via a deeplink from a tag

  // DeepLink URL format:
  // https://teams.microsoft.com/l/entity/<appId>/<entityId>?webUrl=<entityWebUrl>&label=<entityLabel>&context=<context>

  // { "messageContent": { "matching": [ { "label": "#meeting", "value": "#meeting" } ] } }
  // "eyAibWVzc2FnZUNvbnRlbnQiOiB7ICJtYXRjaGluZyI6IFsgeyAibGFiZWwiOiAiI21lZXRpbmciLCAidmFsdWUiOiAiI21lZXRpbmciIH0gXSB9IH0="

  // Pre base64 encoded context.subEntityId:
  // https://teams.microsoft.com/l/entity/2441e82a-5e30-4c02-a442-1431f00f29c0/43287b18-871c-4f19-82a2-986388536409?webUrl=https://gobionic.ngrok.io/&label=#meeting&context={"subEntityId": { "messageContent": { "matching": [ { "label": "#meeting", "value": "#meeting" } ] } } }

  // Post base64 encoded context.subEntityId: {"filter": "#meeting"} => "eyJmaWx0ZXIiOiAiI21lZXRpbmcifQ=="
  // https://teams.microsoft.com/l/entity/2441e82a-5e30-4c02-a442-1431f00f29c0/43287b18-871c-4f19-82a2-986388536409?webUrl=https://gobionic.ngrok.io/&label=#meeting&context={"subEntityId": "eyAibWVzc2FnZUNvbnRlbnQiOiB7ICJtYXRjaGluZyI6IFsgeyAibGFiZWwiOiAiI21lZXRpbmciLCAidmFsdWUiOiAiI21lZXRpbmciIH0gXSB9IH0="}

  // Post URI encoding of params: (e.g. encodeURI() )
  // https://teams.microsoft.com/l/entity/2441e82a-5e30-4c02-a442-1431f00f29c0/43287b18-871c-4f19-82a2-986388536409?webUrl=https%3A%2F%2Fgobionic.ngrok.io%2F&label=%23meeting&context=%7B%22subEntityId%22:%20%22eyAibWVzc2FnZUNvbnRlbnQiOiB7ICJtYXRjaGluZyI6IFsgeyAibGFiZWwiOiAiI21lZXRpbmciLCAidmFsdWUiOiAiI21lZXRpbmciIH0gXSB9IH0=%22%7D

  if (context?.subEntityId) {
    // subEntityId will be base64 encoded string
    console.log("Teams context subEntityId: Original", context.subEntityId);

    // Decode from base64
    const subEntityIdFromBase64 = atob(context.subEntityId);
    console.log(
      "Teams context subEntityId: Decoded from base64",
      subEntityIdFromBase64
    );

    // After base64 decoding, it needs to be parsed as a JSON obj
    const subEntityIdObj = JSON.parse(subEntityIdFromBase64);
    console.log(
      "Teams context subEntityId: Parsed as JSON obj",
      subEntityIdObj
    );

    // appStore.columns = [
    //   plainToClassFromExist(new Column(), {
    //     isSelected: true,
    //     filter: subEntityIdObj
    //   }),
    // ];

    // TODO: Make scrolling to selected work after App.tsx loads
    appStore.addColumn({
      filter: subEntityIdObj,
    });

    console.log(
      "Teams context subEntityId: appStore.columns:",
      appStore.columns
    );
  }
});

// Handle theme changes
microsoftTeams.registerOnThemeChangeHandler(function (theme) {
  setTheme(theme);
});

// Set the desired theme
function setTheme(theme) {
  if (theme) {
    console.log("Setting Teams theme: ", theme);
    // Possible values for theme: 'default', 'light', 'dark' and 'contrast'
    document.body.className =
      "theme-" + (theme === "default" ? "light" : theme);
  }
}

initializeIcons();

ReactDOM.render(
  <React.StrictMode>
    {/* <TelemetryPopover> */}
    <Provider theme={teamsTheme}>
      <QueryClientProvider client={queryClient}>
        <DatabaseProvider database={db}>
          {/* <App /> */}
          {/* <p>Generating DB...</p> */}
          <Preloader />
          {/* <Debug /> */}
        </DatabaseProvider>
      </QueryClientProvider>
    </Provider>
    {/* </TelemetryPopover> */}
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
if (process.env.NODE_ENV === "development") {
  reportWebVitals(console.log);
}
